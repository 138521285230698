@import "bootstrap";

$fa-font-path: "../font-awesome/fonts" !default;
@import "../font-awesome/scss/font-awesome";


body {
  overflow-y: scroll;
  background: theme-color("light");
}

#header {
  margin-bottom: $grid-gutter-width;
  .account-nav {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
  .navbar-env-dev .navbar-brand span {
    background: $success;
    color: map-get($colors, "gray-dark");
  }
  .navbar-env-test .navbar-brand span {
    background: $danger;
    color: map-get($colors, "gray-dark");
  }
  .navbar-env-staging .navbar-brand span {
    background: $danger;
    color: map-get($colors, "gray-dark");
  }
}
#footer {
  height: $grid-gutter-width*4;
}

#sidebar {
  // width: 250px;
}

@include media-breakpoint-up(lg) {
  .sidebar-col {
    max-width: 250px;
  }
  .main-col {
    overflow-x: auto;
  }
}



.table-responsive .table > thead > tr > th {
  white-space: nowrap;
}
.table > thead > tr > th.asc a:after {
  content: ' ▲';
}
.table > thead > tr > th.desc a:after {
  content: ' ▼';
}
.card > .table,
.card > .table-responsive > .table,
.card > .sortable-table > .table,
.card > .sortable-table > .table-responsive > .table, {
  margin-bottom: 0;
}
.card .pagination {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.sortable-table {
  td.sortable_handle {
    vertical-align: middle;
  }
}
.table-sm {
  font-size: $font-size-sm;
}

.CodeMirror {
    // height: auto;
    border: 1px solid $input-border-color;
}

// Quill editor
form .ql-editor p {
  margin-bottom: 10px;
}

.page-accounts-login {
  max-width: 300px;
  margin: 0 auto;
  form {
    margin-bottom: $grid-gutter-width;
  }
}
.page-accounts-password {
  max-width: map-get($container-max-widths, sm);
  margin: 0 auto;
}

.detail-nav {
  margin: $grid-gutter-width/1.5 0;
}


//
// Plupload - Properties / Slideshow
//

#plupload-images-container {
  position: relative;
  margin-bottom: $grid-gutter-width/2;
}
#plupload-images-container.ajax-busy:after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $dark;
  cursor: wait;
  opacity: 0.5;
}
#plupload-images-container .plupload_container {
  padding: 0;
}
#plupload-images-container .plupload-image {
  position: relative;
  margin-bottom: $grid-gutter-width;
  cursor: move;
}
#plupload-images-container .plupload-image .plupload-image-delete {
  position: absolute;
  z-index: 1;
  top: 0;
  right: $grid-gutter-width/2;
  color: theme-color("danger");
  text-shadow: 1px 1px 2px black;
}
#plupload-uploader .plupload_container {
  padding: 0;
}


// PPSearch
// ---

#div_id_amenities > div {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

#crm-message-list {
  .message-item-secondary {
    border-left: solid 2px $secondary;
  }
  .message-item-warning {
    border-left: solid 2px $warning;
  }
}
